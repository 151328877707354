import React, { Fragment } from 'react'
import PropTypes from 'prop-types'

import { StaticImage } from 'gatsby-plugin-image'

const logosFinance = ({ width }) => {
    return (
        <Fragment>
            <div className='container-1 logos-section mobile-padding-reset'>
                <div className='text-center mb-2'>
                    <p className='pb-3'>
                        <a className='h4-like fw-600' href='/customers/'>
                            HUNDREDS OF CUSTOMERS LOVE & TRUST LENSES
                        </a>
                    </p>
                </div>
                <div className='d-flex flex-wrap mx-3 mobile-padding-reset justify-content-sm-between justify-content-center align-items-center'>
                    <div className='col-lg col-6 col-sm-3 '>
                        <a href='/customers/' className='w-100 d-block'>
                            <StaticImage
                                className={`${width ? `w-${width}` : 'w-100'}  zoom-on-hover `}
                                src='../../resources/images/clients/grid/gray/credimi.svg'
                                placeholder='tracedSVG'
                                alt='Credimi'
                            />
                        </a>
                    </div>
                    <div className='col-lg col-6 col-sm-3'>
                        <a href='/customers/'>
                            <StaticImage
                                className={`${width ? `w-${width}` : 'w-100'}  zoom-on-hover `}
                                src='../../resources/images/clients/grid/gray/otpBank.svg'
                                placeholder='tracedSVG'
                                alt='OtpBank'
                            />
                        </a>
                    </div>
                    <div className='col-lg col-6 col-sm-3'>
                        <a href='/customers/'>
                            <StaticImage
                                className='zoom-on-hover  customer-logo-generali'
                                src='../../resources/images/clients/grid/gray/generali.svg'
                                placeholder='tracedSVG'
                                alt='Generali'
                            />
                        </a>
                    </div>
                    <div className='col-lg col-6 col-sm-3'>
                        <a href='/customers/'>
                            <StaticImage
                                className={`${width ? `w-${width}` : 'w-100'}  zoom-on-hover `}
                                src='../../resources/images/clients/grid/gray/carta.svg'
                                placeholder='tracedSVG'
                                alt='Carta'
                            />
                        </a>
                    </div>
                    <div className='col-lg col-6 col-sm-3'>
                        <a href='/customers/'>
                            <StaticImage
                                className={`${width ? `w-${width}` : 'w-100'}  zoom-on-hover `}
                                src='../../resources/images/clients/grid/gray/avanza.svg'
                                placeholder='tracedSVG'
                                alt='Avanza'
                            />
                        </a>
                    </div>
                </div>
            </div>
        </Fragment>
    )
}

export default logosFinance

logosFinance.propTypes = {
    width: PropTypes.number,
}
