import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import HeaderSEO from '../components/partials/HeaderSEO'
import AboveFooterSection from '../components/AboveFooterSection'
import { StaticImage } from 'gatsby-plugin-image'

import LogosFinance from '../components/partials/Logos-finance'
import Spacing from '../components/partials/Spacing'
import Layout from '../components/Layout'

const apacheKafkaForFinance = () => {
  const data = useStaticQuery(graphql`
    query FinancialServicesSEO {
      allContentfulPages(
        filter: {
          pageReference: { pageIdentifier: { eq: "FinancialServices" } }
        }
      ) {
        edges {
          node {
            title {
              title
            }
            keywords {
              keywords
            }
            description {
              description
            }
            oGimage {
              fluid(quality: 100) {
                src
              }
            }
          }
        }
      }
    }
  `)

  const pageSEO = data.allContentfulPages.edges[0].node

  return (
    <Layout>
      <HeaderSEO pageSEO={pageSEO} />
      <section>
        <StaticImage
          className="home-fixed-top"
          src="../resources/images/homepage/hero_lens.svg"
          placeholder="blurred"
          alt="Hero Lense"
        />
        <div className="container-1 hero-margin">
          <div className="d-flex flex-wrap align-items-center">
            <div className="col-md-6 ">
              <h1 className="w-75">
                How financial services can safely scale Apache Kafka
              </h1>
              <div className="pr-5 pt-3 pb-2">
                <p className="hero-description-dark">
                  Kafka self-service and security for heavily regulated apps in
                  a world of fierce, fast innovation.
                </p>
                <p className="hero-description-dark">Here’s how it works.</p>
              </div>
              <div className="roll-button mt-2">
                <a className="bg-red text-white" href="/start/">
                  <span data-hover="Get started">Get started</span>
                </a>
              </div>
            </div>
            <div className="col-md-6">
              <StaticImage
                className="img-fluid mx-auto d-block mobile-margin-top-half"
                src="../resources/images/apache_kafka_for_finance/hero-image.png"
                placeholder="tracedSVG"
                alt="Apache Kafka for Finance"
              />
            </div>
          </div>
        </div>
      </section>
      <Spacing />

      <LogosFinance width={100} />

      <Spacing />

      <section className="bg-cyan mobile-margin-top">
        <div className="container-1 py-5">
          <div className="text-center">
            <h2 className="h1-like pb-1">
              Drowning in tickets from Kafka users?
            </h2>{' '}
            <p className="cera-pro f-24 fw-400 pb-4">(If not, you will be)</p>
            <p className="w-90 mx-auto">
              You’ve got a backlog of Kafka projects to onboard and compliance
              audits to meet; but time is ticking and internal stakeholders are
              breathing fire at the Platform team. Event-streaming was never
              going to be as easy to scale as data-at-rest, and heavy compliance
              requirements make you into a bottleneck for building critical
              applications - from fraud detection to mobile banking.
            </p>
          </div>
        </div>
      </section>

      <Spacing />
      <section>
        <div className="container-1 mobile-margin-top">
          <div className="text-center px-2">
            <h2 className="h1-like">4 burning Kafka questions answered:</h2>
          </div>

          <div className="d-flex flex-wrap pt-5 my-5 align-items-center mobile-margin-reset">
            <div className="col-md-4 col-10 ">
              <p className="h4-like link-color">
                How can I make teams productive without breaking the bank?
              </p>
              <h2>Governance & Productivity</h2>
              <p>
                Protect your data and enable teams using self-service features
                such as multi-tenant platform access with RBAC.
              </p>
              <a
                className="primary-text"
                target="_blank"
                rel="noopener noreferrer"
                href="/resources/data-governance-kafka/"
              >
                Real-time Governance Whitepaper{' '}
                <i className="red-pointer-right"></i>
              </a>
            </div>
            <div className="offset-md-2"></div>
            <div className="col-md-6  col-12 ">
              <StaticImage
                className="pt-2 w-100"
                src="../resources/images/apache_kafka_for_finance/governance.svg"
                placeholder="tracedSVG"
                alt="Governance & Productivity"
              />
            </div>
          </div>

          <div className="d-flex flex-wrap flex-column-reverse flex-sm-row pt-4 align-items-center mobile-margin-top">
            <div className="col-md-6  col-12 ">
              <StaticImage
                className="pt-2 w-100"
                src="../resources/images/apache_kafka_for_finance/compliance-ready.svg"
                placeholder="tracedSVG"
                alt="Compliance Ready"
              />
            </div>
            <div className="offset-md-2 offset-0"></div>
            <div className="col-md-4 col-12 ">
              <p className="h4-like link-color">
                HOW CAN I GIVE SELF-SERVICE ACCESS TO ENGINEERS?
              </p>
              <h2>Compliance-Ready</h2>
              <p>
                Meet compliance and regulation with data discovery & masking,
                audit logs for real-time security monitoring & SSO.
              </p>
              <a
                className="primary-text text-nowrap"
                target="_blank"
                rel="noopener noreferrer"
                href="/blog/2021/03/architecting-apache-kafka-for-gdpr-compliance/"
              >
                Architecting Kafka for GDPR compliance{' '}
                <i className="red-pointer-right"></i>
              </a>
            </div>
          </div>

          <div className="d-flex flex-wrap pt-4 align-items-center mobile-margin-top">
            <div className="col-md-4 col-10 ">
              <p className="h4-like link-color">
                HOW CAN DEVS QUICKLY CONFIGURE & BUILD STREAM PROCESSING FLOWS?
              </p>
              <h2>Process & transform data with SQL</h2>
              <p>
                Create and automate data streaming applications with low or
                no-code by processing and transforming data with SQL.
              </p>
              <a
                className="primary-text"
                target="_blank"
                rel="noopener noreferrer"
                href="/blog/2020/10/kafka-gitops-with-dataops/"
              >
                GitOps for real-time data blog{' '}
                <i className="red-pointer-right"></i>
              </a>
            </div>
            <div className="offset-md-2"></div>
            <div className="col-md-6  col-12 ">
              <StaticImage
                className="w-100"
                src="../resources/images/apache_kafka_for_finance/gitOps.svg"
                placeholder="tracedSVG"
                alt="GitOps"
              />
            </div>
          </div>

          <div className="d-flex flex-wrap flex-column-reverse flex-sm-row pt-4 align-items-center mobile-margin-top">
            <div className="col-md-6  col-12 ">
              <StaticImage
                className="pt-2 w-100"
                src="../resources/images/apache_kafka_for_finance/all-over-observabillity.svg"
                placeholder="tracedSVG"
                alt="All over observability"
              />
            </div>
            <div className="offset-md-2"></div>
            <div className="col-md-4 col-12 ">
              <p className="h4-like link-color">Is my data in kafka healthy?</p>
              <h2>All-over observability</h2>
              <p>
                Explore and investigate data in your streams with SQL to
                troubleshoot performance of your flows and micro services.
              </p>
              <a
                className="primary-text"
                target="_blank"
                rel="noopener noreferrer"
                href="/guide/everything-need-know-about-kafka-monitoring/"
              >
                Kafka monitoring guide <i className="red-pointer-right"></i>
              </a>
            </div>
          </div>
        </div>
      </section>
      <Spacing />

      <section>
        <div className="container-1 mobile-margin-top">
          <div className="d-flex flex-wrap justify-content-center">
            <div className="col-sm-8 col-10">
              <div className="text-center">
                <h2 className="h1-like">
                  Examples of use cases Lenses enables with Kafka
                </h2>
              </div>
            </div>
          </div>

          <div className="d-flex flex-wrap">
            <div className="col-md col-sm-6 col-12 mt-1">
              <div className="border-top-0 rounded-1">
                <StaticImage
                  className="w-100 "
                  src="../resources/images/apache_kafka_for_finance/data-integration.svg"
                  placeholder="tracedSVG"
                  alt="Data integration"
                />
                <div className="pb-4 px-3">
                  <h3 className="text-center fw-700 cera-pro f-20  lh-29 text-uppercase">
                    Data integration
                  </h3>
                  <ul className="red-checked-list">
                    <li>
                      <span>Reducing mainframe costs</span>
                    </li>
                    <li>
                      <span>Integrating market data</span>
                    </li>
                    <li>
                      <span>Security analytics pipelines</span>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-md col-sm-6 col-12 mt-1">
              <div className="border-top-0 rounded-1">
                <StaticImage
                  className="w-100 "
                  src="../resources/images/apache_kafka_for_finance/data-products.svg"
                  placeholder="tracedSVG"
                  alt="AI & data products"
                />
                <div className="pb-4 px-3">
                  <h3 className="text-center fw-700 cera-pro f-20  lh-29 text-uppercase">
                    AI & data products
                  </h3>
                  <ul className="red-checked-list">
                    <li>
                      <span>Risk calculation engine</span>
                    </li>
                    <li>
                      <span>Payment fraud detection</span>
                    </li>
                    <li>
                      <span>Churn prediction</span>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-md col-sm-6 col-12 mt-1">
              <div className="border-top-0 rounded-1">
                <StaticImage
                  className="w-100 "
                  src="../resources/images/apache_kafka_for_finance/applications.svg"
                  placeholder="tracedSVG"
                  alt="Software applications"
                />
                <div className="pb-4 px-3">
                  <h3 className="text-center fw-700 cera-pro f-20  lh-29 text-uppercase">
                    Software applications
                  </h3>
                  <ul className="red-checked-list">
                    <li>
                      <span>Mobile banking</span>
                    </li>
                    <li>
                      <span>Financial roboadvisor chatbot</span>
                    </li>
                    <li>
                      <span>Digital wallet</span>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <Spacing />

      <section>
        <div className="container-1 mobile-margin-top">
          <div className="d-flex flex-wrap justify-content-center">
            <div className="col-sm-8 col-11">
              <div className="text-center">
                <h2 className="h1-like">
                  How finance firms safely scale Kafka with Lenses DataOps
                </h2>
              </div>
            </div>
          </div>
          <div className="d-flex flex-wrap justify-content-between">
            <div className="col-sm-5 col-12">
              <StaticImage
                className="img-fluid mt-4"
                src="../resources/images/apache_kafka_for_finance/avanzaHero.png"
                placeholder="tracedSVG"
                alt="Avanza"
              />
              <StaticImage
                className="mt-5 mb-3 img-fluid"
                src="../resources/images/usecases/kafka-governance/avanza-logo.svg"
                placeholder="tracedSVG"
                alt="Avanza"
              />
              <h2 className="h1-like">
                Leading the Swedish savings market by safely liberating Kafka
                data
              </h2>
              <p>
                How did investment platform Avanza win &gt; 1 million customers
                by enabling developers with a secure data platform?
              </p>
              <a
                className="primary-text"
                target="_blank"
                rel="noopener noreferrer"
                href="/customers/avanza/"
              >
                Read More <i className="red-pointer-right"></i>
              </a>
            </div>
            <div className="col-sm-5 col-12 mobile-margin-top">
              <StaticImage
                className="img-fluid mt-4"
                src="../resources/images/apache_kafka_for_finance/visecaHero.png"
                placeholder="tracedSVG"
                alt="Viseca"
              />
              <div className="mb-3 mt-5 pt-3 w-25">
                <StaticImage
                  className="img-fluid"
                  src="../resources/images/clients/viseca-logo-black.svg"
                  placeholder="tracedSVG"
                  alt="Viseca"
                />
              </div>
              <h2 className="h1-like">
                Delivering <br /> customer apps 10x faster using Kafka
              </h2>
              <p className="pr-3">
                How did a highly regulated Swiss payment provider deliver an
                event-driven marketing platform in under a year?
              </p>
              <a
                className="primary-text"
                target="_blank"
                rel="noopener noreferrer"
                href="/customers/viseca/"
              >
                Read More <i className="red-pointer-right"></i>
              </a>
            </div>
          </div>
        </div>
      </section>
      <Spacing />

      <section className="bg-dark mobile-margin-top">
        <div className="container-1 py-5">
          <div className="mx-auto w-10 mb-4">
            <StaticImage
              className="img-fluid"
              src="../resources/images/clients/viseca-logo-white.svg"
              placeholder="tracedSVG"
              alt="Viseca"
            />
          </div>
          <div className="d-flex flex-wrap justify-content-center">
            <div className="col-sm-6 col-10">
              <p className="text-white f-20 lh-35 text-center">
                &ldquo;I believe the biggest value of Lenses.io is in governance
                support, at operational process and information security level,
                which makes it an ideal solution for enterprises for whom scale
                is a matter of serious attention.&rdquo;
              </p>
            </div>
            <div className="col-sm-7 col-9">
              <div className="d-flex flex-wrap justify-content-center align-items-center">
                <StaticImage
                  className="img-fluid dario-carneli"
                  quality={100}
                  src="../resources/images/apache_kafka_for_finance/dario.png"
                  placeholder="tracedSVG"
                  alt="Dario Carnelli"
                />
                <p className="f-18 fw-900 pl-3 pt-3 text-white">
                  {' '}
                  Dario Carnelli, Governance Expert (Isaca Certified)
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <Spacing />
      <section>
        <div className="container-1">
          <div className="text-center">
            <h2 className="h1-like w-50 mx-auto mobile-w-100 mobile-margin-top-half">
              Architecture & Connectors for finance teams{' '}
            </h2>
          </div>
          <div className="mt-3 mb-5">
            <StaticImage
              className="img-fluid"
              src="../resources/images/apache_kafka_for_finance/architecture.png"
              placeholder="tracedSVG"
              alt="Architecture"
            />
          </div>
          <div className="text-center">
            <a
              className="primary-text"
              target="_blank"
              rel="noopener noreferrer"
              href="/kafka-connectors.tsx"
            >
              See our whole Apache Kafka connector library{' '}
              <i className="red-pointer-right"></i>
            </a>
          </div>
        </div>
      </section>

      <Spacing />
      <section>
        <div className="container-1 mobile-margin-top">
          <div className="text-center">
            <h2 className="h1-like w-50 mx-auto">
              Finance regulations? <br />
              Covered:
            </h2>
          </div>
          <div className="d-flex flex-wrap justify-content-sm-between justify-content-center">
            <div className="col-sm-3 col-7">
              <StaticImage
                className="img-fluid"
                src="../resources/images/apache_kafka_for_finance/GDPR.svg"
                alt="GDPR"
              />
              <p className="text-center mt-2 f-20 fw-900 cera-pro">GDPR</p>
            </div>
            <div className="col-sm-3 col-7">
              <StaticImage
                className="img-fluid"
                src="../resources/images/apache_kafka_for_finance/SEC.svg"
                placeholder="tracedSVG"
                alt="SEC"
              />
              <p className="text-center mt-2 f-20 fw-900 cera-pro">SEC</p>
            </div>
            <div className="col-sm-3 col-7">
              <StaticImage
                className="img-fluid"
                src="../resources/images/apache_kafka_for_finance/CCPA.svg"
                placeholder="tracedSVG"
                alt="CCPA"
              />
              <p className="text-center mt-2 f-20 fw-900 cera-pro">CCPA</p>
            </div>
          </div>
        </div>
      </section>
      <Spacing />

      <AboveFooterSection
        text={'Get started with Lenses over Kafka for financial services:'}
        ctaText={"Let's go"}
        ctaLink="/start/"
      />
    </Layout>
  )
}

export default apacheKafkaForFinance
